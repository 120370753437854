.App {
  text-align: center;
}

.mainContainer {
  background-color: #2c2c54;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formValidation{
  width: 90%;
  max-width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 2rem;
}

.formValidation h3{
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.formDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
}

.formDiv label {
  align-self: flex-start;
  padding: 0.2rem 0;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
}

.formDiv input {
  width: 100%;
  height: 2rem;
  font-size: 14px;
  padding: 0 1rem;
  border-style: none;
  border-radius: 0.5rem;
}

.btnSubmit{
  color: #fff;
  margin-top: 1rem;
  width: 80%;
  min-width: 200px;
  border-radius: 0.5rem;
  border-style: none;
  padding: 0.6rem 0;
  font-weight: bold;
  letter-spacing: 2px;
  transition: all 0.4s;
  background-color: #706fd3;
  font-size: 16px;
}

.btnSubmit:hover {
  width: 83%;
  letter-spacing: 4px;
  background-color: #474787;
}

.fail{
  font-size: 12px;
  color: #ff5252;
  letter-spacing: 2px;
  padding-top: 3px;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .mainContainer {
    justify-content: flex-start;
  }

  .formValidation{
    width: 100%;
    margin-top: 2rem;
  }
}